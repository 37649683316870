<template id="public-medical-report">
  <div style="height: 100%; width: 100%; background: #f6f7fb">
    <v-container style="height: 100%">
      <v-row class="mt-4 mb-8" align="center" no-gutters justify="center">
        <img
          style="max-height: 45px; max-width: 100px"
          alt="Logo"
          class="ui centered image client-logo"
          :src="organization.logo"
        />
      </v-row>
      <v-row align="center" no-gutters justify="center" v-if="loading">
        <v-progress-circular
          indeterminate
          size="45"
          color="primary"
          style="margin-top: 100px"
        ></v-progress-circular>
      </v-row>
      <v-alert
        text
        outlined
        color="deep-orange"
        icon="mdi-alert-circle"
        v-if="showNotAllowedError"
      >
        O paciente selecionado não permite acesso aos seus laudos através do
        link direto. Para visualizar este laudo, acesse a plataforma de
        resultados com seu login e senha
        <router-link :to="{ path: '/login' }">clicando aqui.</router-link>
      </v-alert>
      <v-row v-show="success">
        <v-col>
          <PatientCard ref="patientCard" />
        </v-col>
      </v-row>
      <v-row v-show="success">
        <v-col>
          <ExamsCard ref="examsCard" />
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script type="text/javascript">
import Api from '@/resources/NajaSocialApi'
import { mapState } from 'vuex'
import PatientCard from './PatientCard.vue'
import ExamsCard from './ExamsCard.vue'
import { sortBy } from 'lodash'
export default {
  name: 'PublicMedicalReport',
  components: { PatientCard, ExamsCard },
  computed: {
    ...mapState({
      organization: state => state.data.organization,
    }),
  },
  data() {
    return {
      exams: [],
      loading: false,
      success: false,
      showError: false,
      customText: '',
      showNotAllowedError: false,
    }
  },

  mounted() {
    this.loadData()
  },

  methods: {
    _parsePatient(patient) {
      const phoneTypes = ['Celular', 'Residencial', 'Comercial']
      const contacts = sortBy(patient.contatos, 'tipo.descricao', phoneTypes)
      const phones = []

      const age =
        patient.data_nascimento == null
          ? null
          : this.$moment().diff(
              this.$moment(patient.data_nascimento, 'YYYYMMDD'),
              'years'
            )

      contacts.forEach(contact => {
        if (phoneTypes.includes(contact.tipo.descricao)) {
          phones.push(`${contact.ddd}${contact.numero}`)
        }
      })

      const birthday =
        patient.data_nascimento == null
          ? null
          : this.$moment(patient.data_nascimento, 'YYYYMMDD').format(
              'DD/MM/YYYY'
            )

      const sex = patient.sexo == null ? null : patient.sexo.descricao
      return {
        code: patient.codigo,
        cpf: patient.cpf,
        name: patient.nome,
        deficient: patient.ic_deficiente,
        birthday: birthday,
        old: age >= 65,
        email: patient.email,
        phones: phones.join(', '),
        sex: sex,
      }
    },

    async loadData() {
      this.loading = true
      try {
        const medicalReportRespose = await Api.publicMedicalReport(
          this.$route.params.token,
          {
            origin: this.$route.query.origin || 'core',
          }
        )

        const exams = medicalReportRespose.data.exams
        const patient = this._parsePatient(medicalReportRespose.data.patient)

        const userSettingsResponse = await Api.patientSettings(patient.code)
        const userSettings = userSettingsResponse.data.settings || {}

        const allowsDirectLink =
          userSettings.allows_direct_link_to_medical_report == null
            ? true
            : userSettings.allows_direct_link_to_medical_report

        if (!allowsDirectLink) {
          this.showNotAllowedError = true
          this.loading = false
          return
        }

        this.$refs.patientCard.setPatient(patient)
        this.$refs.examsCard.setExams(exams)

        this.success = true
      } catch (e) {
        this.$toast.show('Erro ao buscar laudo', 'error')
        throw e
      } finally {
        this.loading = false
      }

      this.loading = false
    },
  },
}
</script>
<style scoped>
#public-medical-report {
  color: #5e6368;
}
</style>
