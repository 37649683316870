<template>
  <v-card outlined class="box pa-8" style="color: var(--link-color)">
    <v-row no-gutters>
      <v-col cols="12" sm="2">
        <div
          :class="[{ 'ml-10': !$vuetify.breakpoint.mobile }, 'patient-header']"
        >
          <v-icon size="80"> mdi-account-circle </v-icon>
          <div class="mt-2">
            <v-icon v-show="patient.old" color="primary" class="mr-2" size="24">
              mdi-human-cane
            </v-icon>
            <v-icon v-show="patient.deficient" color="primary" size="24">
              mdi-wheelchair-accessibility
            </v-icon>
          </div>
        </div>
      </v-col>
      <v-col cols="12" sm="10">
        <div class="patient-details">
          <v-row no-gutters class="mb-1">
            <v-col cols="12">
              <strong>Prontuário: </strong> {{ patient.code }}
            </v-col>
          </v-row>
          <v-row no-gutters class="mb-1">
            <v-col cols="12" sm="4">
              <strong>Nome: </strong> {{ patient.name }}
            </v-col>
            <v-col cols="12" sm="4">
              <strong>Data Nasc: </strong> {{ patient.birthday }}
            </v-col>
          </v-row>
          <v-row no-gutters class="mb-1">
            <v-col cols="12" sm="4">
              <strong>Sexo: </strong> {{ patient.sex }}
            </v-col>
            <v-col cols="12" sm="4">
              <strong>Telefone: </strong>{{ patient.phones }}
            </v-col>
            <v-col cols="12" sm="4">
              <strong>E-mail: </strong>{{ patient.email }}
            </v-col>
          </v-row>
        </div>
      </v-col>
    </v-row>
  </v-card>
</template>
<script type="text/javascript">
import { mapState } from 'vuex'
export default {
  name: 'PatientCard',
  computed: {
    ...mapState({
      user: state => state.data.user,
    }),
  },

  data() {
    return {
      loading: false,
      patient: {
        code: null,
        cpf: null,
        name: null,
        birthday: null,
        email: null,
        phones: null,
        sex: null,
      },
    }
  },

  filters: {
    birthday: function (value) {
      if (!value) return ''
      return moment(value, 'YYYYMMDD').format('DD/MM/YYYY')
    },
  },

  methods: {
    setPatient(patient) {
      this.patient = Object.assign(this.patient, patient)
    },
  },
}
</script>
<style scoped lang="scss"></style>
