<template>
  <v-card outlined class="box link-color pa-8">
    <v-row no-gutters align="center" justify="center">
      <v-col cols="12">
        <div :class="{ 'ml-10': !$vuetify.breakpoint.mobile }">
          <div>
            <v-row
              no-gutters
              class="link-color mb-4"
              justify="center"
              align="center"
            >
              <v-col cols="12" sm="2" order="2" order-sm="1">
                <span class="font-weight-medium">
                  <v-icon color="primary">mdi-calendar</v-icon>
                  {{ date }}
                </span>
              </v-col>
              <v-col cols="12" sm="8" order="3" order-sm="2">
                <span>
                  <v-icon color="primary" class="mr-1">mdi-account</v-icon>
                  Solicitante: <strong>{{ doctorRequestor }}</strong>
                </span>
              </v-col>
              <v-col cols="12" sm="2" order="1" order-sm="3">
                <ReportDialog
                  v-if="exams.length > 0"
                  :lau-isn="exams[0].lau_isn"
                  :pad-isn="exams[0].pad_isn"
                  :pac-isn="exams[0].pac_cod"
                  :public-report="true"
                  viewer="acesso direto"
                >
                  <template v-slot:open="{ open }">
                    <v-btn
                      :class="[
                        'text-capitalize',
                        'font-weight-regular',
                        { 'mb-4': $vuetify.breakpoint.mobile },
                      ]"
                      color="primary"
                      dark
                      @click="open"
                    >
                      <v-icon left> mdi-clipboard-text </v-icon>
                      <span>Ver Laudo</span>
                    </v-btn>
                  </template>
                </ReportDialog>
              </v-col>
            </v-row>
            <div v-for="(exam, index) in exams" :key="index">
              <v-row no-gutters justify="center" align="center">
                <v-col cols="12" sm="10" class="font-weight-medium">
                  {{ exam.pro_nome }}
                </v-col>
                <v-col cols="12" sm="2">
                  <v-btn
                    text
                    class="link-color text-capitalize font-weight-regular"
                    @click.prevent="openPACS(exam)"
                  >
                    <v-icon left color="primary"> mdi-image </v-icon>
                    <span class="link-color">Ver Imagens</span>
                  </v-btn>
                </v-col>
              </v-row>
              <v-divider v-if="index < exams.length - 1" class="my-1" />
            </div>
          </div>
        </div>
      </v-col>
    </v-row>
  </v-card>
</template>
<script type="text/javascript">
import { mapState } from 'vuex'
import Api from '@/resources/NajaSocialApi'
import ReportDialog from '@/components/ReportDialog'
export default {
  name: 'MedicalReportImageExamsCard',
  components: { ReportDialog },
  computed: {
    ...mapState({
      user: state => state.data.user,
    }),

    doctorRequestor() {
      return this.exams[0] ? this.exams[0].med_solicitante_nome : null
    },

    date() {
      return this.exams[0]
        ? this.$options.filters.njDate(this.exams[0].pad_data)
        : null
    },
  },

  data() {
    return {
      loading: false,
      patient: {},
      exams: [],
    }
  },

  methods: {
    setExams(exams) {
      this.exams = exams
    },

    openPACS(exam) {
      if (this.loadingPACS) {
        this.$toast.show('PACS carregando ...', 'warn')
        return
      }

      this.loadingPACS = true

      const params = {
        pad_isn: this.$hashids.encode(exam.pad_isn),
        pac_cod: exam.pac_cod,
        emp_sigla: exam.emp_sigla,
        pex_data: exam.pex_data,
        sgr_codmoddicom: exam.sgr_codmoddicom,
        emp_cod: exam.ate_emp_cod,
        pac_datanasc: exam.pac_datanasc,
        pac_nome: exam.pac_nome,
      }

      const windowReference = window.open()

      Api.pacs(params)
        .then(response => {
          windowReference.location = response.data.url
        })
        .catch(error => {
          this.$toast.show('Erro ao carregar PACS', 'error')
          throw error
        })
        .finally(() => {
          this.loadingPACS = false
        })
    },
  },
}
</script>
<style scoped lang="scss">
// .exam {
//   margin-left: 80px;
// }
</style>
